import React from "react";
import SEO from "../components/seo";
import { FAQen } from "../components/TWComponents/faq/FAQen";
import Layout from "../layouts/en";

const FaqPage = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO title="Frequently Asked Questions " />

      <div id="contentWrap">
        <div id="index_content" className="admin-setting">
          <div className="faq">
            <h1 className="text--center">Frequently Asked Questions</h1>
            <div className="container container--800">
              <FAQen />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FaqPage;
